import Vue from 'vue'
import VueRouter from 'vue-router'

// 解决重复點擊路由報錯的BUG
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
	return originalPush.call(this, location).catch((err) => err)
}

Vue.use(VueRouter)

const routes = [
	// {
	// 	path: '/',
	// 	name: 'MapView',
	// 	component: () => import('@/views/MapView')
	// },
	{
		// path: '/\@:lat\,:lng,:zoom\z',
		// path: '/:latLngZ?',
		path: '/:config?/:latLngZ?',
		name: 'MapView',
		component: () => import('@/views/MapView'),
		// props: (route) => {
		// 	// console.log(route);
		// 	let props = {};
		// 	const regex = /\@(\d+(\.\d+)?)\,(\d+(\.\d+)?),(\d+)z/;
		// 	[ props.lat, props.lng, props.zoom ] = route.params.latLngZ.split(regex).filter(t => t && t.indexOf(".") != 0);
		// 	// console.log(props);

		// 	return props
		// }
	}
]

const router = new VueRouter({
	// mode: 'history',
	routes
})

export default router

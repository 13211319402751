<template>
	<v-app>
		<router-view />
	</v-app>
</template>

<script>
export default {
	name: "App",
	mounted() {
		this.vhCheck();
		window.addEventListener('resize', this.vhCheck);
	},
	methods: {
		vhCheck() {
			let vh = window.innerHeight * 0.01;
			// Then we set the value in the --vh custom property to the root of the document
			document.documentElement.style.setProperty('--vh', `${vh}px`);
		}
	}
}
</script>

<style lang="sass">
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+TC&display=swap')

*
	text-transform: none	
html, body
	position: relative
	overflow: hidden
	font-family: 'Noto Sans TC', '微軟正黑體', 'Microsoft JhengHei', sans-serif
	height: 100vh
	height: calc(var(--vh, 1vh) * 100)
	width: 100vw
	padding: 0
	margin: 0
#app
	position: relative
	.v-enter-active, .v-leave-active
		transition: opacity 0.5s
	.v-enter-from, .v-leave-to
		opacity: 0
	.v-enter-to, .v-leave-from
		opacity: 1
</style>